import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../Hooks/useFetch";
import { transactionsURL } from "../Hooks/URL";
import { userContext } from "../Contexts/userContext";
import Transactions from "../Templates/Home/Transactions/Transactions";

function TransactionsPage() {
  const { contextData, setContextData } = useContext(userContext);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchData, setSearchData] = useState("");
  const [search, setSearch] = useState("");
  const [Open, setOpen] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const { data, loading, error } = useFetch(transactionsURL);

  useEffect(() => {
    if (!loading && !error && data) {
      setTransactions(data.data);
      setPageCount(data.numberOfPages);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "transactions", data: "" });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Transactions
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      searchData={searchData}
      search={search}
      setSearchData={setSearchData}
      setSearch={setSearch}
      transactions={transactions?.transactions}
      totalSum={transactions?.totalSum}
      navigate={navigate}
      handleChangePage={handleChangePage}
      Open={Open}
      setOpen={setOpen}
      loading={loading}
      data={data}
      error={error}
      pageCount={pageCount}
      setTransactions={setTransactions}
    />
  );
}

export default TransactionsPage;
