import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../Hooks/useFetch";
import { teamsURL } from "../Hooks/URL";
import { userContext } from "../Contexts/userContext";
import Teams from "../Templates/Home/Teams/Teams";

function TeamsPage() {
  const { contextData, setContextData } = useContext(userContext);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchData, setSearchData] = useState("");
  const [search, setSearch] = useState("");
  const [Filter, setFilter] = useState("");
  const [Open, setOpen] = useState(false);

  const [teams, setTeams] = useState([]);
  const { data, loading, error } = useFetch(
    teamsURL,
    page,
    rowsPerPage,
    search,
    null,
    false,
    `${Filter}`
  );

  useEffect(() => {
    if (!loading && !error && data) {
      setTeams(data.data);
      setPageCount(data.numberOfPages);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "teams", data: "" });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Teams
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      searchData={searchData}
      search={search}
      setSearchData={setSearchData}
      setSearch={setSearch}
      teams={teams}
      navigate={navigate}
      handleChangePage={handleChangePage}
      Open={Open}
      setOpen={setOpen}
      loading={loading}
      data={data}
      error={error}
      pageCount={pageCount}
      setTeams={setTeams}
      setFilter={setFilter}
      filter={Filter}
    />
  );
}

export default TeamsPage;
