import { Box } from "@mui/material";
import { images } from "../../../../Assets/assetsHelper";
import "./TeamsDetails.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TeamsDetails({ team, loading, setGo }) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      {team && !loading ? (
        <div className="TeacherCont">
          <div className="greyBox">
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <h2 style={{ fontSize: "30px", margin: "0 0 10px 0" }}>
                  Team Name: {team?.name}
                </h2>

                <PrimaryButton
                  classBtn={"center editButton"}
                  text={"Go to Application"}
                  click={() =>
                    navigate(`/applications/${team?.application_id}`)
                  }
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "5px",
                    color: "var(--text-secondary)",
                    backgroundColor: "#1976d2",
                    height: "40px",
                    padding: "0 30px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "column" },
                  width: "100%",
                }}
              >
                <ul className="confirmList">
                  <li>
                    <span className="listTitle">Organization:</span>{" "}
                    {team?.organizationName}
                  </li>
                  <li>
                    <span className="listTitle">Category:</span>{" "}
                    {team?.categoryName}
                  </li>
                  <li>
                    <span className="listTitle">Age Group:</span>{" "}
                    {team?.ageGroupName}
                  </li>
                  <li>
                    <span className="listTitle">Coach Name:</span>{" "}
                    {team?.coach?.name}
                  </li>
                  <li>
                    <span className="listTitle">Coach Email:</span>{" "}
                    {team?.coach?.email}
                  </li>
                  <li>
                    <span className="listTitle">Coach Phone Number:</span>{" "}
                    {team?.coach?.contactNumber}
                  </li>
                  <li>
                    <span className="listTitle">Team Members:</span>
                  </li>
                </ul>
                <TableContainer
                  component={Paper}
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">name</StyledTableCell>
                        <StyledTableCell align="center">
                          Birthday
                        </StyledTableCell>
                        <StyledTableCell align="center">Age</StyledTableCell>
                        <StyledTableCell align="center">
                          National ID
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {team?.students?.map((stu) => (
                        <StyledTableRow key={stu.name}>
                          <StyledTableCell align="center">
                            {stu.name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {stu?.birthday?.split("T")?.at(0)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {stu?.age}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {stu?.nationalId}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default TeamsDetails;
