export const BaseURL = `https://api.codechallenge-championship.org`;
// export const BaseURL = `https://apistaging.codechallenge-championship.org`;
// export const BaseURL = `https://skillcodeeg.com/api`;
// export const BaseURL = `http://192.168.137.233:4242/api`;

export const loginURL = `${BaseURL}/dashboard/login`;
export const logoutURL = `${BaseURL}/dashboard/logout`;
export const userInfo = `${BaseURL}/dashboard/loggedInInfo`;
export const applicationsURL = `${BaseURL}/dashboard/applications`;
export const categoriesURL = `${BaseURL}/dashboard/categories`;
export const teamsURL = `${BaseURL}/dashboard/teams`;
export const transactionsURL = `${BaseURL}/dashboard/transactions`;
export const teamsExcelURL = `${BaseURL}/dashboard/teams/excel`;
export const dashboardURL = `${BaseURL}/dashboard`;
export const activateURL = `${BaseURL}/activate`;
export const schoolURL = `${BaseURL}/schools`;
export const teacherURL = `${BaseURL}/teachers`;
export const studentURL = `${BaseURL}/students`;
export const courseURL = `${BaseURL}/courses`;
export const classesURL = `${BaseURL}/classes`;
export const listSchoolsURL = `${BaseURL}/schools?sort=name&fields=id,name`;
export const listTeachersURL = `${BaseURL}/teachers/list`;
export const listCoursesURL = `${BaseURL}/courses?sort=title&fields=id,title`;
export const listStudentsURL = `${BaseURL}/students/list`;

export const reportsURL = (id) => `${BaseURL}/classes/${id}/reports`;
export const sessionsClassesURL = (id) => `${BaseURL}/classes/${id}/sessions`;
export const reportsClassesURL = (id) => `${BaseURL}/classes/${id}/reports`;
