import "./Home.css";
import React, { useState } from "react";
import SideBar from "../../Components/Organisms/Bars/SideBar/SideBar";
import NavBar from "../../Components/Organisms/Bars/NavBar/NavBar";
import { Link, Route, Routes } from "react-router-dom";
import { useCookies } from "react-cookie";
import useLogout from "../../Hooks/useLogout";
import { logoutURL } from "../../Hooks/URL";
import CategoriesPage from "../../Pages/CategoriesPage";
import ApplicationsDetailsPage from "../../Pages/ApplicationsDetailsPage";
import ApplicationsPage from "../../Pages/ApplicationsPage";
import DashboardPage from "../../Pages/DashboardPage";
import CategoryDetailsPage from "../../Pages/CategoryDetailsPage";
import TeamsPage from "../../Pages/TeamsPage";
import TeamsDetailsPage from "../../Pages/TeamsDetailsPage";
import TransactionsPage from "../../Pages/TransactionsPage";

function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const [cookies, setCookie] = useCookies(["TOKEN"]);
  const [goSave, setGoSave] = useState(false);
  function handleShowMenu() {
    setShowMenu(!showMenu);
  }
  const { saveState } = useLogout({
    url: logoutURL,
    goSave,
    setGoSave,
  });

  return (
    <div className="Home">
      <NavBar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="container">
        {/*  <div className={`sidebar ${showMenu ? "sidebarOpen" : "sidebarClose"}`}>
          <span
            className={"closeIcon mobile " + (showMenu ? "" : "hidden")}
            onClick={() => handleShowMenu()}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 20px",
            }}
          >
            {cookies.TOKEN ? (
              <PrimaryButton
                classBtn={"center mobile"}
                text={"Logout"}
                click={() => {
                  setGoSave(true);
                  // setShowMenu(false);
                }}
                style={{
                  textTransform: "capitalize",
                  width: "100px",
                  borderRadius: "5px",
                  color: "#068399",
                  backgroundColor: "white",
                  height: "50px",
                }}
              />
            ) : (
              <Link
                to="/Login"
                className="login"
                onClick={() => setShowMenu(false)}
              >
                <PrimaryButton
                  classBtn={"center mobile"}
                  text={"Login"}
                  style={{
                    textTransform: "capitalize",
                    width: "100px",
                    borderRadius: "5px",
                    color: "#068399",
                    backgroundColor: "white",
                    height: "50px",
                  }}
                />
              </Link>
            )}
            <CloseIcon
              sx={{
                fontSize: 30,
                marginBottom: 2,
                marginLeft: 2,
                cursor: "pointer",
              }}
            />
          </span>
        </div> */}
        <div className="main">
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route exact path="/categories" element={<CategoriesPage />} />
            <Route path="/categories/:id" element={<CategoryDetailsPage />} />
            <Route path="/applications" element={<ApplicationsPage />} />
            <Route
              path="/applications/:id"
              element={<ApplicationsDetailsPage />}
            />
            <Route path="/teams" element={<TeamsPage />} />
            <Route path="/teams/:id" element={<TeamsDetailsPage />} />
            <Route path="/transactions" element={<TransactionsPage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Home;
