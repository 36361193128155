import { Button, Menu, MenuItem } from "@mui/material";
import "./Filter.css";
import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import useFetch from "../../../Hooks/useFetch";
import {
  categoriesURL,
  countriesURL,
  listCoursesURL,
  listSchoolsURL,
  listTeachersURL,
} from "../../../Hooks/URL";
import useFetchWhen from "../../../Hooks/useFetchWhen";
import { userContext } from "../../../Contexts/userContext";

function Filter({
  ageGroup = false,
  categoryGroup = false,
  paiedApp = false,
  setFilter,
}) {
  const { contextData, setContextData } = useContext(userContext);
  const [age, setAge] = useState(null);
  const [category, setCategory] = useState(null);
  const [paied, setPaied] = useState(null);

  const { data: categories } = useFetchWhen(categoriesURL, true);
  const { data: ageGroups } = useFetchWhen(
    `${categoriesURL}/${category}`,
    category != null
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="filterButton"
      >
        <FilterListIcon className="filterIcon" /> Add Filter
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="filterArea">
          <p>Filters:</p>
          {paiedApp ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Paied:</label>
              <TextFieldInput
                select
                newValue={paied}
                name={"paied"}
                change={(e) => setPaied(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Status
                </MenuItem>
                <MenuItem value={"1"}>Paied</MenuItem>
                <MenuItem value={"0"}>Not Paied</MenuItem>
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {categoryGroup ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Category:</label>
              <TextFieldInput
                select
                newValue={category}
                name={"category"}
                change={(e) => {
                  setCategory(e.target.value);
                  setAge(null);
                }}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Category
                </MenuItem>
                {categories?.data?.map((option) => (
                  <MenuItem value={option?.id}>{option?.name}</MenuItem>
                ))}
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {ageGroup ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Age Group:</label>
              <TextFieldInput
                select
                newValue={age}
                name={"age"}
                change={(e) => setAge(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Age Group
                </MenuItem>
                {ageGroups?.data?.ageGroups?.map((option) => (
                  <MenuItem value={option?.id}>{option?.name}</MenuItem>
                ))}
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <PrimaryButton
              classBtn={"center addFilter"}
              text={"Apply filter"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                textWrap: "noWrap",
                margin: "0 5px",
              }}
              click={() => {
                setFilter(
                  `${age ? `&ageGroupId=${age}` : ""}${
                    category ? `&categoryId=${category}` : ""
                  }${paied ? `&applicationIsPaied=${paied}` : ""}`
                );
              }}
            />
            <PrimaryButton
              classBtn={"center addFilter"}
              text={"Clear"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                textWrap: "noWrap",
                margin: "0 5px",
              }}
              click={() => {
                setAge(null);
                setCategory(null);
                setPaied(null);
                setFilter("");
              }}
            />
          </div>
        </div>
      </Menu>
    </div>
  );
}

export default Filter;
